function configureLogger(log, cliargs) {
    // SET DEBUG FLAGS RIGHT AWAY, EVEN BEFORE REQUIRING SOME THINGS
    if (cliargs.debug) {
        log.addDebug(cliargs.debug);
    }
    if (cliargs.d) {
        if (cliargs.debug) {
            console.log("Warning: don't use '-d' with '--debug', ignoring `-d`.");
        } else {
            log.addDebug('*,-sockjs*:*');
        }
    }

    // default when no quiet flag
    if (!cliargs.q) {
        // normal things turned on
        log.addDebug('*op:*,*info:*,*stats:*,*init:*');
    }
    log.addDebug('*error:*');

    if (!cliargs.expressVerbose) {
        log.addDebug('-express:router:layer,-express:router:route');
    }
}

module.exports = {
    configureLogger,
};

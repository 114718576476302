// This eslint rule is useful at a later stage of development
/* eslint-disable no-unused-vars */

import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { Actions, jsonformsReducer } from '@jsonforms/core';
import { materialFields, materialRenderers } from '@jsonforms/material-renderers';
import {
 BrowserRouter as Router, Route, IndexRoute, Link, Switch,
} from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import { DogLogger, NovemDoc } from '@novem/novemdoc';
import ratingControlTester from './ratingControlTester';
import RatingControl from './RatingControl';
import uischema from './uischema.json';
import schema from './schema.json';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import clihelp from './util/clihelp.js';
import endpoints from './util/endpoints';

// import App from './App';

// lazy load
// import Portal from './Portal.js';
// import DocEdit from './DocEdit';

const LoginPage = lazy(() => import('./pageComponents/LoginPage.js'));
const Portal = lazy(() => import('./pageComponents/Portal.js'));
const DocEdit = lazy(() => import('./pageComponents/DocEdit.js'));

const log = new DogLogger('trunkfe');

const cliargs = {
    q: false, // quiet mode
    d: true, // debug mode  all logging on
    debug: null, // string debug matcher
};
clihelp.configureLogger(log, cliargs);
log.init('process.env', process.env);

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

// const data = {
//   name: 'Send email to Adrian',
//   description: 'Confirm if you have passed the subject\nHereby ...',
//   done: true,
//   recurrence: 'Daily',
//   rating: 3,
// };

const data = [{
    locationId: '',
    source: '',
    quantity: 0,
    strain: false,
    isMother: false,
    dateOfBirth: {
        year: 0,
        month: 0.1,
        day: 0,
    },
    inventoryId: '-',
    states: [
        '',
    ],
    plantType: '',
    _ndoc: {
        doctype: 'plant',
    },
}];

function ndocReducer(state = {}, action) {
    log.detail('state, action', state, action);
    switch (action.type) {
        case 'ndoc/doctypeIs':
            const newprops = {
                doctype: action.doctype,
            };
            const newstate = { ...state, ...newprops };
            return newstate;
        default:
            return state;
    }
    // unreachable: return state;
}

function trunkfeReducer(state = {}, action) {
    log.detail('state, action', state, action);
    switch (action.type) {
        case 'trunkfe/sessionUpdate':
            const newprops = {
                session: action.session,
            };
            const newstate = { ...state, ...newprops };
            return newstate;
        default:
            return state;
    }
    // unreachable: return state;
}

const store = createStore(
  combineReducers({
      jsonforms: jsonformsReducer(),
      ndoc: ndocReducer,
      trunkfe: trunkfeReducer,
  }),
  {
    jsonforms: {
      fields: materialFields,
      renderers: materialRenderers,
    },
    ndoc: {
        doctype: 'blank',
    },
    trunkfe: {
        session: {
            loggedIn: false,
        },
    },
  },
);

function parseLocation() {
  console.log('i55:', window.location);
}

parseLocation();

// store.dispatch(Actions.init(data, schema, uischema));
if (false) { // stopped, getting by api
    store.dispatch(Actions.init(data, schema));
}

// restore session if available
const { random_number } = window.sessionStorage;
if (random_number) {
    (async () => {
        const session = await endpoints.api({
            name: 'session',
            session: { token: random_number },
        });
        console.log('session', session);
        store.dispatch({
            type: 'trunkfe/sessionUpdate',
            session,
        });
    })();
}
store.dispatch(Actions.registerRenderer(ratingControlTester, RatingControl));

function setSchema(args) {
    console.log('args', args);
    const {
 schema, doctype, defaultData, uischema,
} = args;
    store.dispatch({
        type: 'ndoc/doctypeIs',
        doctype,
    });
    const act = Actions.init(defaultData, schema, uischema);
    console.log('i78:', act, doctype);

    store.dispatch(act);
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#055505',
        },
        secondary: {
            main: '#d0d0ff',
        },
        panel: {
            main: '#209020',
        },
    },
    spacing: 4,
});

const reduxState = {
    setSchema,
};

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <Router>
                <Suspense fallback={<div style={{ padding: '5px' }}>Loading Trunk Portal ...</div>}>
                    <Switch>
                        {/* Login Page for anyone without a session. */}
                        <Route
                          path="/login"
                          render={(props) => <LoginPage {...props} />}
                        />
                        {/* Document Editing Interface. */}
                        <Route
                          path="/docedit/:doctype*"
                          render={(props) => <DocEdit {...props} setSchema={setSchema} />}
                        />
                        {/* Portal, the root site. */}
                        <Route
                          path="/"
                          exact
                          render={(props) => <Portal doctype="" {...props} />}
                        />
                    </Switch>
                </Suspense>
            </Router>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('root'),
);
// removed <Route path="/old" exact component={App} />

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root')
// );

// let's wait for the service worker until we need it
// makes seeing updates a pain in various situations (primarilly when served from real server).
if (false) registerServiceWorker();
unregister();

// rule good for later stage
/* eslint-disable no-unused-vars */
import { DogLogger, NovemDoc } from '@novem/novemdoc';

export const packageLogger = new DogLogger('tFE');
packageLogger.addDebug('*tFE*');
packageLogger.debug('START LOGGER');

export function subLogger(...args) {
    return packageLogger.subLogger(...args);
}

export default packageLogger;

// rule good for later stage
/* eslint-disable no-unused-vars */

/* ^^REFACTOR^^DOCUMENTATION^^
    code name FRACTAL ENDPOINTS
    The idea is I don't want the whole api of all endpoints in this module that goes
    to the browser. We have lazy loading at the index level and can use that elsewhere
    and will be adding priv checks to subdirectories so that privs are checked for
    given components in given directories.

    It is  therefore in-the-module that I want the apis, but... of course, shared as
    well... all components that need to call contactRequest endpoints can import
    the request modules that have the endpoint url functions, they have the
    arguments anyway.

    Also I don't know what I was thinking doing this this export.  Well I do, it was just
    a table of functions originally and I was going to have all of the endpoints here.
    Still.
    SEE NEW CODE IN `trunkApi.js`
*/

import get from 'lodash/get';
import set from 'lodash/set';
import { DogLogger, NovemDoc } from '@novem/novemdoc';
import pkgLogger from '../packageLogger.js';
import sleep from '../util/sleep.js';

const APIURL = process.env.REACT_APP_APIURL
                ? process.env.REACT_APP_APIURL
                : '';
const log = pkgLogger.subLogger('endpoints');

const endpoints = {
     login: () => `${APIURL}/api/login`,
     logout: () => `${APIURL}/api/logout`,
     session: () => `${APIURL}/api/session`,
     saveLead: () => `${APIURL}/api/lead/_update`,
     deleteLead: () => `${APIURL}/api/lead/_delete`,
     getContactList: () => `${APIURL}/api/doclist/contactRequest`,

     // ^^REFACTOR: FRACTAL ENDPOINTS: these two endpoints should be in callers.
     saveCurrentDocument: () => `${APIURL}/api/save`,
     // I want to move these endpoints to protected modules...
     fetchDoctypeInfo: (doctype) => `${APIURL}/api/doctype/${doctype}`,

     // reusable fetch functions
     fetch: async (url, request = {}, options = {}) => {
         try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                referrer: 'client',
                body: JSON.stringify(request),
            });
            const answer = await response.json();
            return answer;
        } catch (err) {
            return {
                // eslint-disable-next-line key-spacing, quotes
                error: "true",
                message: `${url}: ${err.message}`,
            };
        }
    },
    api: async ({
        name, doc = {}, session = null, urlParams,
        }) => {
        let endpoint;
        const body = {
            doc,
        };
        if (!session) {
            session = window.sessionStorage.session
                ? JSON.parse(window.sessionStorage.session) : {};
        }
        console.log("(ep80)", JSON.stringify(session, null, 4));
        let textAnswer = null;
        try {
            const endpointFactory = endpoints[name];
            endpoint = endpointFactory(urlParams);
            set(body, '_pgx.session_token', session.token);
            const response = await fetch(endpoint, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                referrer: 'client',
                body: JSON.stringify(body),
             });
             // Server error feed HTML
             textAnswer = await response.text();
             const answer = JSON.parse(textAnswer);
             return answer;
         } catch (e) {
             log.error(`Serious error in api request: ${e.message}\n${e.stack}`);
             return {
                 status: 'error',
                 message: `${endpoint}: ${textAnswer}`,
             };
         }
    },
};

export default endpoints;
